// src/Footer.js
import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MdOutlineWork } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { IoCall } from "react-icons/io5";
// Optional: Custom marker icon
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { Link } from "react-router-dom";
import logo from '../assets/logo.png'
const Footer = () => {
  // Use a ref to store the map instance
  const mapRef = useRef(null);

  useEffect(() => {
    // Initialize the map only if it hasn't been initialized yet
    if (mapRef.current === null) {
      mapRef.current = L.map("leaflet-map").setView([28.59841, 77.34215], 13); // Coordinates for London

      // Add OSM tile layer
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "&copy; OpenStreetMap contributors",
      }).addTo(mapRef.current);

      // Define custom marker icon
      const defaultIcon = L.icon({
        iconUrl: markerIcon,
        shadowUrl: markerShadow,
      });

      // Add a marker at the desired location
      L.marker([28.59841, 77.34215], { icon: defaultIcon })
        .addTo(mapRef.current)
        .bindPopup(
          "<b>Orchid Enterprises India</b><br />orchidenterprisesindia.com."
        )
        .openPopup();
    }

    // Cleanup function to remove the map instance on unmount
    return () => {
      if (mapRef.current !== null) {
        mapRef.current.remove();
        mapRef.current = null; // Reset the ref to allow reinitialization if the component remounts
      }
    };
  }, []);

  return (
    <div className="bg-gray-700 text-white flex flex-col items-center justify-center w-full mt-4 px-8">
      <div className="flex items-center justify-center w-full borderBottom xl:flex-col xl:gap-4 mt-4 ">
        <div className="flex items-center justify-center w-full md:flex-col md:items-start md:justify-start">
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <img src={logo} className="w-30 h-12" alt="" />
            <div className="flex items-center justify-center gap-2">
            <MdOutlineWork/>
            <div className="flex flex-col gap-0 headerText">
              <span className="text-gray-200 ">
                Mon - Fri 09:00am to 11:00pm
              </span>
              <span className="text-gray-200 ">Sat and Sun closed</span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <GrMail/>
            <a href="mailto:prashant@orchidenterprisesindia.com" className="flex flex-col gap-0 headerText">
              <span className="text-gray-200 ">Email</span>
              <span className="text-gray-200 ">
              prashant@orchidenterprisesindia.com
              </span>
            </a>
          </div>
          <div className="flex items-center justify-center gap-2">
            <IoCall/>
            <a href="tel:9711103709" className="flex flex-col gap-0 headerText">
              <span className="text-gray-200 ">Call us</span>
              <span className="text-gray-200 ">+91 9711103709</span>
            </a>
          </div>
          </div>
          <div className="flex flex-col items-start justify-start w-1/2 gap-4 mt-4">
            <h1 className="font-semibold">Pages</h1>
            <div className="flex flex-col items-start justify-start gap-2 md:flex-row md:justify-center">
              {NavMenu.map((nav, i) => (
                <Link to={nav.path} className={`cursor-pointer  `}>
                  {nav.menu}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="p-2 w-full rounded-lg">
          <div
            id="leaflet-map"
            style={{ height: "50vh", width: "100%", borderRadius: "10px" }}
          ></div>
        </div>
      </div>
      <div className="py-4">
        <p className="text-sm">
          © Copyright 2024 , All Rights Reserved by OrchidEnterprisesIndia.com.
        </p>
      </div>
    </div>
  );
};

export default Footer;
const NavMenu = [
  {
    menu: "Home",
    path: "/",
  },
  {
    menu: "Services",
    path: "/services",
  },
  {
    menu: "Gallery",
    path: "/gallery",
  },
  {
    menu: "About",
    path: "/about",
  },
];
