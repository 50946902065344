import React, { useEffect, useState } from 'react';
import useDocumentTitle from '../utils/useDocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import { fetchImages } from '../utils/imageSlice';
import LazyLoad from 'react-lazyload';
import BoxesLoader from '../utils/BoxesLoader';
import Masonry from 'react-masonry-css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import gallery from '../assets/gallery.png'
const Gallery = () => {
  const bounceTransition = {
    type: "spring",
    stiffness: 100,
    damping: 10,
    duration: 1,
  };

  const { ref: one, inView: inViewImages } = useInView({ threshold: 0.1 });

  useDocumentTitle("Gallery | www.OrchidEnterprisesIndia.com");

  const [filteredCategory, setFilteredCategory] = useState('All');
  const [filteredImages, setFilteredImages] = useState([]);

  const dispatch = useDispatch();
  const categoryImages = useSelector((state) => state.images.categoryImages);
  const status = useSelector((state) => state.images.status);
  const error = useSelector((state) => state.images.error);

  useEffect(() => {
    dispatch(fetchImages());
  }, [dispatch]);

  useEffect(() => {
    if (categoryImages && categoryImages.images) {
      filterImages('All');
    }
  }, [categoryImages]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const filterImages = (category) => {
    if (categoryImages && categoryImages.images) {
      if (category === 'All') {
        setFilteredImages(categoryImages.images);
      } else {
        const filtered = categoryImages.images.filter(img =>
          img.categories.some(cat => cat.toLowerCase() === category.toLowerCase())
        );
        setFilteredImages(filtered);
      }
    }
  };

  const handleCategoryFilter = (category) => {
    setFilteredCategory(category);
    filterImages(category);
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  if (status === 'loading') {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <BoxesLoader />
      </div>
    );
  }

  return (
    <div className="w-full">
    <div className="w-full flex items-center justify-between pr-8">
      <div className="py-8 px-8 relative flex flex-col items-start gap-4 ">
        <h1 className="text-violet-600 font-semibold text-4xl">Our Gallery</h1>
        <p className="py-1 text-lg text-gray-700 max-w-prose">
          Exploring Global Frontiers: A Visual Journey of Innovation in Retail. Discover how our dynamic organization collaborates globally, blending design, technology, and compliance. Through vibrant images, witness our teams' extensive travels and market insights, shaping products that resonate worldwide.
        </p>
      </div>
      <div className="w-[40%] h-auto">
        <img src={gallery} className='w-full h-full' alt="" />
      </div>
    </div>

      {categoryImages ? (
        <div className="w-full my-12 flex flex-col items-center justify-center">
          <span className="flex items-center justify-center w-auto lg:w-auto gap-4 lg:gap-8 md:gap-4 headerBg rounded-lg py-2 px-8 md:px-2 flex-wrap">
            {categoryImages.categories?.length > 0 && categoryImages.categories.map((item, i) => (
              <span
                key={i}
                className={`text-violet-500 cursor-pointer ${filteredCategory.toLowerCase() === item.toLowerCase() ? 'font-semibold border-b-2 border-violet-500' : ''}`}
                onClick={() => handleCategoryFilter(item)}
              >
                {capitalizeFirstLetter(item)}
              </span>
            ))}
          </span>

          <motion.div
            className="px-8 mt-12 w-full"
            ref={one}
            initial={{ opacity: 0, y: -50 }}
            animate={inViewImages ? { opacity: 1, y: 0 } : {}}
            transition={bounceTransition}
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid flex w-full gap-2"
              columnClassName="my-masonry-grid_column"
            >
              {filteredImages.length > 0 ? (
                filteredImages.map((img, i) => (
                  <LazyLoad key={i} height={200} once>
                    <div className="borderStyle p-1 rounded-md">
                      <img src={img.imageUrl} className="w-full rounded-md h-full object-cover" alt="" />
                    </div>
                  </LazyLoad>
                ))
              ) : (
                <p className="text-gray-500">No images available for this category.</p>
              )}
            </Masonry>
          </motion.div>
        </div>
      ) : (
        <div className="w-full h-full text-3xl">No data found: {error}</div>
      )}
    </div>
  );
};

export default Gallery;
