import React, { useEffect } from "react";
import Slider from "../utils/AwesomeSlider";
import { useSelector, useDispatch } from "react-redux";
import { fetchBannerImages } from "../utils/imageSlice";

const Banner = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBannerImages());
  }, [dispatch]);
  const bannerImages = useSelector((state) => state.images.bannerImages);
  const status = useSelector((state) => state.images.status);
  const error = useSelector((state) => state.images.error);

  return (
    <>
      <div className="w-full h-[450px] md:h-[350px] sm:h-[250px] xs:h-[200px] relative">
        <Slider bannerImages={bannerImages} status={status} error={error} />
        
      </div>
    </>
  );
};

export default Banner;
