import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { GrMail } from "react-icons/gr";
import { IoCall } from "react-icons/io5";
import { MdOutlineWork } from "react-icons/md";
// Ensure NavMenu is imported correctly

const MobileMenu = ({ setIsMenuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleRoute = (route) => {
    navigate(route);
    setIsMenuOpen(false); // Close the menu after navigating
  };

  const menuAnimation = {
    initial: { translateX: "-100%" },
    animate: { translateX: "0%" },
    exit: { translateX: "-100%" },
    transition: { duration: 0.3, ease: "easeInOut" },
  };

  return (
    <AnimatePresence>
      <motion.div
        key="menu"
        initial={menuAnimation.initial}
        animate={menuAnimation.animate}
        exit={menuAnimation.exit}
        transition={menuAnimation.transition}
        className="absolute top-[155px] w-full right-0 bg-violet-500 h-[450px] z-50 flex flex-col items-center justify-start overflow-y-auto"
      >
        <div className="w-full max-w-[90vw] h-full flex flex-col items-center justify-start gap-8 py-8">
          <div className="w-full flex items-center justify-center gap-4">
            {NavMenu.map((nav, i) => (
              <button
                key={nav.path}
                className={`cursor-pointer text-white ${
                  location.pathname === nav.path
                    ? "border-b-2 border-white font-semibold"
                    : ""
                }`}
                onClick={() => handleRoute(nav.path)}
              >
                {nav.menu}
              </button>
            ))}
          </div>
          <div className="flex flex-col gap-8 w-full ">
            <div className="flex items-center gap-2">
              <MdOutlineWork />
              <div className="flex flex-col gap-0 ml-2 headerText">
                <span className="text-gray-200">
                  Mon - Fri 09:00am to 11:00pm
                </span>
                <span className="text-gray-200">Sat and Sun closed</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <GrMail />
              <a
                href="mailto:prashant@orchidenterprisesindia.com"
                className="flex flex-col gap-0 ml-2 headerText"
              >
                <span className="text-gray-200">Email</span>
                <span className="text-gray-200">
                  prashant@orchidenterprisesindia.com
                </span>
              </a>
            </div>
            <div className="flex items-center gap-2">
              <IoCall />
              <a
                href="tel:9711103709"
                className="flex flex-col gap-0 ml-2 headerText"
              >
                <span className="text-gray-200">Call us</span>
                <span className="text-gray-200">+91 9711103709</span>
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MobileMenu;

const NavMenu = [
  {
    menu: "Home",
    path: "/",
  },
  {
    menu: "About",
    path: "/about",
  },
  {
    menu: "Services",
    path: "/services",
  },
  {
    menu: "Gallery",
    path: "/gallery",
  },
];
