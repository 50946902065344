import React from "react";
import Banner from "../components/Banner";
import search from "../assets/search.jpeg";
import stratergy from "../assets/stratergy.png";
import useDocumentTitle from "../utils/useDocumentTitle";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Home = () => {
  useDocumentTitle("Home | OrchidEnterprisesIndia");

  const bounceTransition = {
    type: "spring",
    stiffness: 100,
    damping: 10,
    duration: 1,
  };

  const { ref: whyChooseUsRef, inView: inViewWhyChooseUs } = useInView({
    threshold: 0.1,
  });
  const { ref: ourStrategyRef, inView: inViewOurStrategy } = useInView({
    threshold: 0.1,
  });
  const { ref: videosRef, inView: inViewVideos } = useInView({
    threshold: 0.1,
  });

  return (
    <div>
      <Banner />
      {/* why choose us */}
      <motion.div
        className="flex items-center justify-center gap-16 mt-12 p-[4rem] lg:flex-col"
        ref={whyChooseUsRef}
        initial={{ opacity: 0, y: -50 }}
        animate={inViewWhyChooseUs ? { opacity: 1, y: 1 } : {}}
        transition={bounceTransition}
      >
        <img
          src={search}
          className="w-[400px] lg:w-full h-auto rounded-lg"
          alt="Search"
        />
        <div className="w-[600px] text-start mt-12 lg:w-full">
          <h1 className="w-full font-bold text-2xl text-violet-500">
            Our Strength
          </h1>
          <p className="text-md font-thin py-4">
            We fully understand the changing demographic and economic situations
            in the world, specially the pressures and opportunities provided by
            the technology in modern day retail. We have a dynamic organization
            and have done strategic tie ups with people in different parts of
            the world to keep feeding us with design, product development and
            product compliance inputs. Our Design and Product development teams
            travel extensively to keep up to date with markets.
          </p>
          <a
            href="mailto:prashant@orchidenterprisesindia.com"
            className="ButtonBg py-2 px-4 rounded-lg text-white"
          >
            Contact Us
          </a>
        </div>
      </motion.div>

      {/* Our strategy */}
      <motion.div
        ref={ourStrategyRef}
        className="my-4rem flex items-center justify-center gap-16 p-[4rem] lg:flex-col"
        initial={{ opacity: 0, y: -50 }}
        animate={inViewOurStrategy ? { opacity: 1, y: 0 } : {}}
        transition={bounceTransition}
      >
        <div className="w-[600px]  text-start lg:w-full">
          <h1 className="w-full font-bold text-2xl text-violet-500 ">
            Why Choose Us
          </h1>
          <p className="text-md font-thin py-4">
            As a company we offer services to buyers regardless of how they
            would like to buy the product. We have created a model for trading
            where we take the ownership of products from design and development
            till the merchandise is on board. We design and develop specific
            ranges based on trends given to us and present to our customers. As
            clients do not have to deal with multiple commercial identities it
            reduces logistics and banking paperwork. We continuously upgrade our
            design and product profiles and present coordinated ranges ,
            covering all product categories for Home. The production facilities
            with work with are up to the standard, audited and approved, a we
            command better prices and quality as we use up major share of
            production capacity of the facility and we pass this on
            to our customers.
          </p>
        </div>
        <img
          src={stratergy}
          className="w-[400px] lg:w-full h-auto"
          alt="Strategy"
        />
      </motion.div>

      {/* Videos */}
      <div className="px-8">
        <h1 className="text-2xl font-semibold">We handle everything for you</h1>
        <div className="grid grid-cols-4 lg:grid-cols-2 items-center justify-center gap-2 py-4">
          {videoSample.map((item, i) => (
            <motion.div
              ref={videosRef}
              className="w-full flex flex-col items-center justify-between h-auto borderStyle rounded-lg p-1"
              key={i}
              initial={{ opacity: 0, y: -50 }}
              animate={inViewVideos ? { opacity: 1, y: 0 } : {}}
              transition={bounceTransition}
            >
              {/* video player with autoplay */}
              <video
                className="video-player rounded-lg"
                src={item.url}
                autoPlay
                loop
                muted
                playsInline
                controls={false} // optional, to allow user to control playback
              />
              <h1 className="w-full text-center py-1">{item.title}</h1>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;

const videoSample = [
  {
    title: "Design and sourcing",
    url: "https://res.cloudinary.com/dgqatpnej/video/upload/v1719188230/dapt89ky4o67ncjbc5yp.mp4",
  },
  {
    title: "Production",
    url: "https://res.cloudinary.com/dgqatpnej/video/upload/v1719188229/g1w4gz0l4l5aidipeugx.mp4",
  },
  {
    title: "Quality Control",
    url: "https://res.cloudinary.com/dgqatpnej/video/upload/v1719188229/zsrplmlypwooxqvoceex.mp4",
  },
  {
    title: "Logistics",
    url: "https://res.cloudinary.com/dgqatpnej/video/upload/v1719188229/rqhdtg1e1q5cfbh4rpif.mp4",
  },
];
