import React, { useRef } from "react";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useScroll, motion, useTransform } from "framer-motion";
const About = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [1, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);
  useDocumentTitle("About | www.OrchidEnterprisesIndia.com");

  return (
    <div className="">
      <div className="bgAbout py-8 px-8 relative flex flex-col items-start gap-4  backgroundImage">
        <h1 className="text-violet-500 font-bold text-4xl">About Us</h1>
        
        <p className="py-1 text-lg text-gray-100 max-w-prose">
        We provide end to end sourcing and supply chain solutions to customers worldwide ,our customer base has a wide spectrum of buyers from mass to high street retailers, importers & wholesalers. 
        </p>
        <a
          href="mailto:prashant@orchidenterprisesindia.com"
          className="ButtonBg py-2 px-4 rounded-lg text-white"
        >
          Contact Us
        </a>
      </div>

      <motion.div
        className="my-8 px-8"
        ref={ref}
        style={{
          scale: scaleProgress,
          opacity: opacityProgress,
        }}
      >
        <h1 className="text-violet-600 font-semibold text-2xl">Our Company</h1>
        <p className="py-4 text-lg text-gray-500 max-w-4xl">
          We established ORCHID ENTERPRISES to provide efficient and optimum
          supply chain services to our customers and be a partner in their
          growth , we firmly believe in providing opportunities to people in our
          organization and be a platform where they can fully express themselves
          professionally. We are based in Noida (Delhi), India. we provide end
          to end sourcing and supply chain solutions to customers worldwide ,our
          customer base has a wide spectrum of buyers from mass to high street
          retailers, importers & wholesalers. We work on the principle of
          complete transparency and integrity between buyers,
          factories and our team.
        </p>
        <a
          href="/"
          className="inline-block border-2 border-violet-600 py-2 px-4 rounded-lg text-violet-600 hover:bg-violet-600 hover:text-white transition-colors duration-200"
        >
          Go to Home
        </a>
      </motion.div>

      <motion.div
        className="my-8 px-8"
        ref={ref}
        style={{
          scale: scaleProgress,
          opacity: opacityProgress,
        }}
      >
        <h1 className="text-violet-600 font-semibold text-2xl">Our Team</h1>
        <p className="py-4 text-lg text-gray-500 max-w-4xl">
          The team at ORCHID ENTERPRISES is a blend of experienced professionals
          and experts from all functional areas of business, right from
          sourcing, design, product development, quality and logistics. We also
          have technical experts in all three major product groups: Home ,
          Tableware and Kitchenware and Ceramics and porcelain , to provide
          right product at right price.
        </p>
        
      </motion.div>
    </div>
  );
};

export default About;
