import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";// Import MobileMenu component
// Import NavMenu array
import { FaWhatsapp } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
const MiniHeader = ({setIsMenuOpen}) => {
  const navigate = useNavigate();
  const location = useLocation();
  

  const handleRoute = (route) => {
    navigate(route);
   // Close mobile menu when navigating
    setIsMenuOpen(false); // Close hamburger menu icon
  };

  const toggleMenu = () => {
     // Toggle mobile menu
    setIsMenuOpen((prev) => !prev); // Toggle hamburger icon state
  };

  return (
    <div className="relative flex items-center justify-between px-8 py-2 w-full h-10 headerBg">
      <span className="hidden md:block cursor-pointer" onClick={toggleMenu}>
        <GiHamburgerMenu size='30' />
      </span>
      <ul className="flex items-center justify-center gap-8 md:hidden">
        {NavMenu.map((nav, i) => (
          <button
            key={nav.path}
            className={`cursor-pointer  ${
              location.pathname === nav.path
                ? "border-b-2 border-white font-semibold"
                : ""
            }`}
            onClick={() => handleRoute(nav.path)}
          >
            {nav.menu}
          </button>
        ))}
      </ul>
      <div className="flex items-center justify-center gap-4">
      <IoLogoLinkedin size="30"/>
      <a
        href={`https://wa.me/${9711103709}/?text=Hi There!`}
        className="text-end ButtonBg py-1 px-4 headerText text-white rounded-lg flex items-center justify-center gap-2"
      >
        Ask Catalogue <FaWhatsapp/>
      </a>
      </div>

      
    </div>
  );
};

export default MiniHeader;
const NavMenu = [
  {
    menu: "Home",
    path: "/",
  },
  {
    menu: "About",
    path: "/about",
  },
  {
    menu: "Services",
    path: "/services",
  },
  {
    menu: "Gallery",
    path: "/gallery",
  },
];