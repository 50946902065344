import imageUrlBuilder from '@sanity/image-url';
import myConfiguredSanityClient from '@sanity/client';




export const client = myConfiguredSanityClient({
  
  // projectId: process.env.SANITY_PROJECT_ID,
//   projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  projectId: '0yv0ipkk',
  dataset: 'production',
  apiVersion: '2021-10-21',
  useCdn: true,
  // token: process.env.SANITY_TOKEN,
  token: process.env.REACT_APP_SANITY_TOKEN,
  
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source).url()