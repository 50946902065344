import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
// Import your custom styles
import BoxesLoader from './BoxesLoader';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = ({bannerImages, status,error}) => {
  if(status === 'loading'){
    return <div className="w-screen h-screen flex items-center justify-center">
      <BoxesLoader/>
    </div>
  }
  if(status === 'failed'){
    return <div className="w-screen h-screen flex items-center justify-center">
      <span className="text-3xl">Error in fetching Banner data : {error}</span>
    </div>
  }
  return (
    bannerImages && 
    <div className="custom-slider-container bg-black z-0 ">
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={2000}
        className="min-w-full h-[100%] "
      >
      {bannerImages && bannerImages?.images?.map((img, i)=>(
        <div className='w-full h-full flex items-center justify-center bg-black'>
          <img src={img.imageUrl} alt="banner" className="object-cover w-screen h-auto" />
        </div>
      ))}
      </AutoplaySlider>
    </div>
  );
};
export default Slider;
