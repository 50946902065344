import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "./sanity";

export const fetchImages = createAsyncThunk('/images/fetchImages', async()=>{
    const res = await client.fetch(`
        *[_type == "categoryImageGallery"][0]{
          categories,
          images[]{
            "imageUrl": image.asset->url,
            categories
          }
        }
      `);
      return res;
})
export const fetchBannerImages = createAsyncThunk('images/fetchBannerImages', async () => {
    const res = await client.fetch(`
      *[_type == 'bannerImageGallery'][0]{
        images[]{
          "imageUrl": asset->url,
        }
      }
    `);
    return res;
  });

const imagesSlice = createSlice({
    name:'images',
    initialState:{
        categoryImages: [],
        bannerImages: [],
        status: 'idle',
        error: null,
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(fetchImages.pending, (state)=>{
            state.status ='loading'
        })
        .addCase(fetchImages.fulfilled, (state,action)=>{
            state.status ='succeeded'
            state.categoryImages = action.payload;
        })
        .addCase(fetchImages.rejected, (state,action)=>{
            state.status ='failed'
            state.error = action.error.message
        })
        .addCase(fetchBannerImages.pending, (state)=>{
            state.status ='loading'
        })
        .addCase(fetchBannerImages.fulfilled, (state,action)=>{
            state.status ='succeeded'
            state.bannerImages = action.payload;
        })
        .addCase(fetchBannerImages.rejected, (state,action)=>{
            state.status ='failed'
            state.error = action.error.message
        })
    }
})
export default imagesSlice.reducer;