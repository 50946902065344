import React from "react";
import { MdOutlineWork } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { IoCall } from "react-icons/io5";
import logo from '../assets/logo.png'
const Header = () => {
  return (
    <div className="w-full relative ">
      <div className="w-full px-8 flex items-center justify-between h-[100px] ">
      <a href="/">
        <img src={logo} className="w-35 h-12" alt=""/>
      </a>
        <div className="flex items-center justify-center gap-8 xl:hidden">
          <div className="flex items-center justify-center gap-2">
            <MdOutlineWork/>
            <div className="flex flex-col gap-0 headerText">
              <span className="text-gray-600 ">
                Mon - Fri 09:00am to 11:00pm
              </span>
              <span className="text-gray-600 ">Sat and Sun closed</span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <GrMail/>
            <a href="mailto:prashant@orchidenterprisesindia.com" className="flex flex-col gap-0 headerText">
              <span className="text-gray-600 ">Email</span>
              <span className="text-gray-600 ">
              prashant@orchidenterprisesindia.com
              </span>
            </a>
          </div>
          <div className="flex items-center justify-center gap-2">
            <IoCall/>
            <a href="tel:9711103709" className="flex flex-col gap-0 headerText">
              <span className="text-gray-600 ">Call us</span>
              <span className="text-gray-600 ">+91 9711103709</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
