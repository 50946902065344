import React from "react";
import useDocumentTitle from "../utils/useDocumentTitle";
import Logi1 from "../assets/serviceBg.png";

// Images
import serv1 from "../assets/ser1.png";
import serv2 from "../assets/serv2.png";
import serv3 from "../assets/serv3.png";
import serv4 from "../assets/ser4.png";
import serv5 from "../assets/ser5.png";
import serv6 from "../assets/ser6.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const services = [
  {
    imgUrl: serv1,
    title: "Vendor Selection Alignment",
  },
  {
    imgUrl: serv2,
    title: "Design Support",
  },
  {
    imgUrl: serv3,
    title: "Product Development",
  },
  {
    imgUrl: serv4,
    title: "Product and Management",
  },
  {
    imgUrl: serv5,
    title: "Compliance and Audits",
  },
  {
    imgUrl: serv6,
    title: "Logistics Support",
  },
];

const Services = () => {
  const bounceTransition = {
    type: "spring",
    stiffness: 100,
    damping: 10,
    duration:4
  };

  const { ref: one, inView: inViewWhyChooseUs } = useInView({ threshold: 0.1 });
  const { ref: two, inView: inViewOurStrategy } = useInView({ threshold: 0.1 });
  useDocumentTitle("Services | www.OrchidEnterprisesIndia.com");

  return (
    <div className="w-full">
      <div className="py-12 bg-cover bg-center px-8 relative flex items-center justify-between md:flex-col md:items-center md:justify-center w-full gap-8 ">
        <div className="w-full md:flex-col md:items-center md:justify-center ">
          <h1 className="text-violet-600 font-bold text-4xl gradientText">
            Our Services
          </h1>
          <p className="py-4 w-full text-md text-gray-700">
            We pride ourselves in our values of reliability and transparency. We
            aim to understand our partners’ business needs and implement and
            deliver efficiently.
          </p>
        </div>
        <div className="w-full ml-12 flex items-center justify-end">
          <img src={Logi1} alt="Logistic" className="w-[70%] h-auto" />
        </div>
      </div>
      <div className="my-8 px-8 bgService py-4" >
        <motion.div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 gap-6 p-16 rounded-lg border-2 border-white"
        ref={one}
        initial={{ opacity: 0, y: -50 }}
        animate={inViewWhyChooseUs ? { opacity: 1, y: 0 } : {}}
        transition={bounceTransition}
        >
          {services.map((item, i) => (
            <div
              className="flex flex-col items-center justify-between borderStyle border-gray-300 rounded-lg p-4"
              key={i}
            >
              <img
                src={item.imgUrl}
                className="w-[30%] h-auto mb-4"
                alt={item.title}
              />
              <h1 className="text-center text-white text-lg font-medium">{item.title}</h1>
            </div>
          ))}
        </motion.div>
      </div>
      <motion.div className="px-8 grid grid-cols-2 lg:grid-cols-1 gap-4 items-start justify-start my-8"
        ref={two}
        initial={{ opacity: 0, y: -50 }}
        animate={inViewOurStrategy ? { opacity: 1, y: 0 } : {}}
        transition={bounceTransition}
      >
        <div className="">
          <h1 className="text-2xl font-semibold text-violet-500">Vendor Selection Alignment</h1>
          <p className="text-gray-600 text-md">
            Product / Material Specialization <br /> Capacity and Capabilities{" "}
            <br /> Business volume <br />
            Technical expertise and investments in technology<br></br>
            <ul className="mt-2">
              <span className="text-lg font-semibold">Benefits</span>
              <li>-Expansion / Future Plans Sustainability</li>
              <li>-Initiatives Compliance</li>
              <li>-Social / Technical</li>
              <li>-Can ship both Direct import / Domestic</li>
            </ul>
          </p>
        </div>
        <div className="">
          <h1 className="text-2xl font-semibold text-violet-500">Design Support</h1>
          <p className="text-gray-600 text-md">
            <ul className="mt-2">
              <span className="text-lg font-semibold">Benefits</span>
              <li>
                -Support in ideating and visualizing new product concepts.
              </li>
              <li>
                -Detailed 2D and 3D technical drawings to ensure accurate sample
                development.
              </li>
              <li>
                -Flexibility to explore various materials during prototype
                development to find the best fit for the final product.
              </li>
            </ul>
          </p>
        </div>
        <div className="">
          <h1 className="text-2xl font-semibold text-violet-500">PRODUCT DEVELOPMENT</h1>
          <p className="text-gray-600 text-md">
            <ul className="mt-2">
              <span className="text-lg font-semibold">Benefits</span>
              <li>
                -Strategic assignment of product development projects to
                suitable suppliers to meet specifications and design needs.
              </li>
              <li>
                -Offering alternative re-engineering solutions to ensure the
                product is commercially viable.
              </li>
              <li>
                -Comprehensive testing to ensure the product meets all required
                performance standards.
              </li>
            </ul>
          </p>
        </div>
        <div className="">
          <h1 className="text-2xl font-semibold text-violet-500">PRODUCTION PLANNING & MANAGEMENT</h1>
          <p className="text-gray-600 text-md">
            <ul className="mt-2">
              <span className="text-lg font-semibold">Benefits</span>
              <li>
                <b>Timely Target Achievement - </b>Efficient management of production
                timelines and cost negotiation to meet targets.
              </li>
              <li>
                <b>Quality Assurance - </b>Continuous quality control and
                pre-production audits to address potential issues early.
              </li>
              <li>
                <b>Effective Communication - </b>Clear communication with suppliers on
                product details, timelines, and test protocols for smooth
                execution.
              </li>
            </ul>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Services;
