import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./Pages/Home";
import Services from "./Pages/Services.js";
import About from "./Pages/About";
import Gallery from "./Pages/Gallery";
import Footer from "./components/Footer.js";
import { Provider } from "react-redux";
import store from "./utils/store.js";
import BoxesLoader from "./utils/BoxesLoader.js";
import MiniHeader from "./components/MiniHeader.js";
import MobileMenu from "./components/MobileMenu.js";

const AppContent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
 
  return (
    <div className="min-h-screen flex flex-col relative">
      <Header />
      <MiniHeader  setIsMenuOpen={setIsMenuOpen}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      <Footer />
      {isMenuOpen && <MobileMenu setIsMenuOpen={setIsMenuOpen}></MobileMenu>}
    </div>
  );
};

const App = () => {
  const [delayed, setDelayed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayed(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  if (!delayed) {
    return (
      <div className="flex-grow flex items-center justify-center h-screen">
        <BoxesLoader />
      </div>
    );
  }
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
};

export default App;
